import { render, staticRenderFns } from "./form.vue?vue&type=template&id=63849728&"
import script from "./form.vue?vue&type=script&lang=js&"
export * from "./form.vue?vue&type=script&lang=js&"
import style0 from "./form.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBreadcrumbLink: require('@chakra-ui/vue').CBreadcrumbLink, CBreadcrumbItem: require('@chakra-ui/vue').CBreadcrumbItem, CBreadcrumb: require('@chakra-ui/vue').CBreadcrumb, CDivider: require('@chakra-ui/vue').CDivider, CHeading: require('@chakra-ui/vue').CHeading, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormControl: require('@chakra-ui/vue').CFormControl, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox, CSelect: require('@chakra-ui/vue').CSelect, CFlex: require('@chakra-ui/vue').CFlex})
