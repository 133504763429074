<template>
  <c-box flexGrow="1">
    <c-breadcrumb
      fontSize="14px"
      lineHeight="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="brand.900"
          as="router-link"
          to="/admin/nutritionists"
        >
          Manajemen Ahli Gizi
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link isCurrent>
          {{ id ? "Ubah" : "Tambah" }} Ahli Gizi
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-heading
      as="h3"
      font-family="Montserrat"
      font-weight="bold"
      font-size="24px"
      line-height="36px"
      mb="50px"
    >
      {{ id ? "Ubah" : "Tambah" }} Ahli Gizi
    </c-heading>

    <form max-w="590px" v-chakra>
      <c-form-control :is-invalid="$v.email.$invalid" is-required mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Email
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="email"
            error-border-color="red.300"
            placeholder="Masukkan Email"
            height="62px"
            v-model="email"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px" is-required :isInvalid="firstName === ''">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Nama Depan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Nama Depan"
            height="62px"
            v-model="firstName"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Nama Belakang
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Nama Belakang"
            height="62px"
            v-model="lastName"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Foto Ahli Gizi
        </c-form-label>
        <label
          for="img-preview"
          v-chakra="{
            bg: 'white',
            cursor: 'pointer',
            border: '1px solid #C4C4C4',
            boxSizing: 'border-box',
            borderRadius: '6px',
            w: '180px',
            h: '100%',
            minHeight: '180px',
            d: 'flex',
            p: '0',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }"
        >
          <c-image
            :src="getPreviewImage(photoUrl)"
            :h="
              !getPreviewImage(photoUrl).includes('icon-photo')
                ? '100%'
                : '94px'
            "
            alt="img program"
            mx="auto"
            border-radius="6px"
          />
        </label>
        <c-input
          style="display: none"
          type="file"
          id="img-preview"
          accept=".jpg, .jpeg, .png"
          error-border-color="red.300"
          height="62px"
          @change="onFileChange($event, 'preview')"
        />
      </c-form-control>

      <c-form-control
        display="flex"
        mb="10px"
        alignItems="center"
        justifyContent="space-between"
      >
        <c-form-label
          color="#555555"
          fontWeigh="500"
          fontSize="18px"
          lineHeight="27px"
          fontFamily="Roboto"
        >
          Pendidikan
        </c-form-label>
      </c-form-control>
      <c-box
        v-for="(item, index) in education"
        :key="'edu' + index"
        position="relative"
      >
        <c-box position="absolute" top="40px" right="-45px">
          <c-button
            @click="removeEducation(index)"
            ml="18px"
            min-width="auto"
            variant-color="red"
            variant="link"
          >
            <c-image
              :src="require('@/assets/icon-trash-line.svg')"
              alt="empty"
            />
          </c-button>
        </c-box>
        <c-form-control mb="25px">
          <c-form-label
            fontSize="14px"
            color="#555555"
            fontWeigh="400"
            fontFamily="Roboto"
          >
            Degre
          </c-form-label>
          <c-input-group size="md">
            <c-select
              v-model="item.level"
              height="62px"
              placeholder="Masukkan Level"
            >
              <option
                v-for="item in degre"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </c-select>
          </c-input-group>
        </c-form-control>

        <c-form-control is-required mb="25px">
          <c-form-label
            fontSize="14px"
            color="#555555"
            fontWeigh="400"
            fontFamily="Roboto"
          >
            Gelar
          </c-form-label>
          <c-input-group size="md">
            <c-input
              type="text"
              placeholder="Masukkan Gelar"
              height="62px"
              v-model="item.title"
            />
          </c-input-group>
        </c-form-control>

        <c-form-control mb="25px">
          <c-form-label
            fontSize="14px"
            color="#555555"
            fontWeigh="400"
            fontFamily="Roboto"
          >
            Universitas
          </c-form-label>
          <c-input-group size="md" z-index="1">
            <ModelListSelect
              :list="dataUniversities"
              v-model="item.university"
              option-value="id"
              option-text="name"
              placeholder="Masukkan Universitas"
              style="
                height: 62px;
                display: inline-flex;
                align-items: center;
                font-size: 16px;
              "
            />
          </c-input-group>
          <c-input-group v-if="item.university === '0'" size="md" mt="25px">
            <c-input
              type="text"
              error-border-color="red.300"
              placeholder="Masukkan Universitas"
              height="62px"
              @keyup="onSetOtherUniversity($event, index)"
              v-model="item.otherUniversity"
            />
          </c-input-group>
        </c-form-control>
      </c-box>
      <c-button
        v-if="education && education.length < 2"
        @click="addEducation"
        :isDisabled="isEmptyEducation"
        variant-color="brand"
        variant="outline"
        borderRadius="60px"
        h="62px"
        w="100%"
        left-icon="add"
        mb="25px"
      >
        Tambah Pendidikan
      </c-button>

      <c-form-control :isInvalid="rating === ''" is-required mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Rating
        </c-form-label>
        <c-input-group size="md" mt="15px">
          <StarRating
            :increment="0.5"
            v-model="rating"
            star-size="26"
            text-class="hidden"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control :isInvalid="quota === ''" is-required mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Quota
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="number"
            error-border-color="red.300"
            placeholder="Masukkan Quota"
            height="62px"
            v-model="quota"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Spesialisasi / Keahlian
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Spesialisasi / Keahlian"
            height="62px"
            v-model="specialization"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px" is-required>
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Nomor STR
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Nomor STR"
            height="62px"
            v-model="str"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Usia Klien
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Usia Klien"
            height="62px"
            v-model="clientAgeHandled"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Bahasa
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Bahasa"
            height="62px"
            v-model="languages"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        display="flex"
        mb="10px"
        alignItems="center"
        justifyContent="space-between"
      >
        <c-form-label
          color="#555555"
          fontWeigh="500"
          fontSize="18px"
          lineHeight="27px"
          fontFamily="Roboto"
        >
          Pengalaman Bekerja
        </c-form-label>
      </c-form-control>
      <c-box
        v-for="(item, index) in workExperiences"
        :key="index"
        position="relative"
      >
        <c-box position="absolute" top="40px" right="-45px">
          <c-button
            @click="removeWorkExperience(index)"
            ml="18px"
            min-width="auto"
            variant-color="red"
            variant="link"
          >
            <c-image
              :src="require('@/assets/icon-trash-line.svg')"
              alt="empty"
            />
          </c-button>
        </c-box>
        <c-form-control mb="25px">
          <c-form-label
            fontSize="14px"
            color="#555555"
            fontWeigh="400"
            fontFamily="Roboto"
          >
            Nama Perusahaan
          </c-form-label>
          <c-input-group size="md">
            <c-input
              type="text"
              error-border-color="red.300"
              placeholder="Masukkan Pengalaman Bekerja"
              height="62px"
              v-model="item.companyName"
            />
          </c-input-group>
        </c-form-control>
        <c-flex align="center">
          <c-form-control
            mb="25px"
            :style="{ width: '50%', marginRight: '20px' }"
          >
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Tahun Masuk
            </c-form-label>
            <c-input-group size="md">
              <c-input
                type="text"
                error-border-color="red.300"
                placeholder="Masukkan Tahun Masuk"
                height="62px"
                v-model="item.startYear"
              />
            </c-input-group>
          </c-form-control>
          <c-form-control mb="25px" :style="{ width: '50%' }">
            <c-form-label
              fontSize="14px"
              color="#555555"
              fontWeigh="400"
              fontFamily="Roboto"
            >
              Tahun Keluar
            </c-form-label>
            <c-input-group size="md">
              <c-input
                type="text"
                error-border-color="red.300"
                placeholder="Masukkan Tahun Keluar"
                height="62px"
                v-model="item.endYear"
              />
            </c-input-group>
          </c-form-control>
        </c-flex>
      </c-box>
      <c-button
        @click="addWorkExperience"
        :isDisabled="isEmptyworkExperiences"
        variant-color="brand"
        variant="outline"
        borderRadius="60px"
        h="62px"
        w="100%"
        left-icon="add"
      >
        Tambah Pengalaman Kerja
      </c-button>

      <c-flex mt="28px" justify-content="space-between">
        <c-button
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          color="#555555"
          @click="onCancel"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="brand"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :isDisabled="isDisabledSubmit"
          @click="onSubmit"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import generalMixin from "@/utils/general-mixins";
import { email } from "vuelidate/lib/validators";
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import StarRating from "vue-star-rating";

export default {
  name: "ManagementNutritionistsForm",
  components: { ModelListSelect, StarRating },
  mixins: [generalMixin],
  data() {
    return {
      email: null,
      firstName: null,
      lastName: "",
      photoUrl: null,
      education: [
        {
          level: null,
          title: null,
          university: null,
          otherUniversity: null,
        },
      ],
      specialization: null,
      str: null,
      clientAgeHandled: null,
      languages: "Indonesia",
      rating: null,
      quota: null,
      degre: [
        { value: "d1", name: "D1" },
        { value: "d3", name: "D3" },
        { value: "d4", name: "D4" },
        { value: "s1", name: "S1" },
        { value: "s2", name: "S2" },
        { value: "s3", name: "S3" },
      ],
      workExperiences: [],
      isUploading: false,
    };
  },
  computed: {
    ...mapGetters({
      axios: "axios",
      item: "admNutritionists/item",
      universities: "general/universities",
    }),
    dataUniversities() {
      return this.universities.map((dt) => {
        dt.id = dt.id.toString();
        return dt;
      });
    },
    id() {
      return this.$route.params.id;
    },
    isDisabledSubmit() {
      if (
        !this.str ||
        !this.email ||
        this.$v.email.$invalid ||
        !this.firstName ||
        // !this.specialization ||
        // !this.clientAgeHandled ||
        // !this.languages ||
        this.rating === null ||
        this.quota === null
      )
        return true;
      return false;
    },
    isEmptyworkExperiences() {
      if (this.workExperiences) {
        let emptyObj = this.workExperiences.findIndex(
          (dt) => !dt.companyName || !dt.endYear || !dt.startYear
        );
        if (emptyObj > -1) return true;
      }
      return false;
    },
    isEmptyEducation() {
      if (this.education) {
        let emptyObj = this.education.findIndex(
          (dt) => !dt.level || !dt.title || !dt.university
        );
        if (emptyObj > -1) return true;
      }
      return false;
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          if (!val.workExperiences) val.workExperiences = [];
          this.email = val.email;
          this.firstName = val.firstName;
          this.lastName = val.lastName;
          this.photoUrl = val.photoUrl;
          this.education = val.education;
          this.specialization = val.specialization;
          this.str = val.str;
          this.clientAgeHandled = val.clientAgeHandled;
          this.languages = val.languages;
          this.rating = val.rating ? val.rating.toString() : "0";
          this.quota = val.quota;
          this.workExperiences = val.workExperiences;
        }
      },
    },
  },
  validations: {
    email: { email },
  },
  methods: {
    ...mapActions({
      createNutritionists: "admNutritionists/createNutritionists",
      detailNutritionists: "admNutritionists/detailNutritionists",
      updateNutritionists: "admNutritionists/updateNutritionists",
      loadUniversities: "general/loadUniversities",
    }),
    onSetOtherUniversity(e, index) {
      this.education[index].otherUniversity = e.target.value;
    },
    onFileChange(input, index) {
      let self = this;
      if (self.isUploading) return;
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0];
        self.isUploading = true;
        self.$toast({
          description: `Uploading...`,
          status: "info",
          duration: null,
          position: "top",
          variant: "subtle",
        });
        if (file.type.includes("image") && file.size > 2097152) {
          self.isUploading = false;
          return self.$toast({
            title: "Failed",
            description: `This file can't be uploaded, because it (${self.formatBytes(
              file.size
            )}) exceeds the maximum file size (2 MB).`,
            status: "error",
            duration: 10000,
            position: "bottom-right",
            variant: "subtle",
          });
        }
        // Upload avatar to API
        let formData = new FormData();
        formData.append("file", input.target.files[0]);
        for (var pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }

        self.axios
          .post(`/v1/users/upload`, formData)
          .then((it) => {
            this.isUploading = false;
            this.photoUrl = it.data.data.url;
          })
          .catch(() => {
            self.$toast({
              title: "Failed",
              description: "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
    },
    onSubmit() {
      let params = {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        photoUrl: this.photoUrl,
        education: this.education,
        specialization: this.specialization,
        str: this.str,
        clientAgeHandled: this.clientAgeHandled,
        languages: this.languages,
        rating: this.rating,
        quota: this.quota,
        workExperiences: this.workExperiences,
      };
      if (this.id) {
        params.id = this.id;
        return this.updateNutritionists(params)
          .then(() => {
            this.$toast({
              title: "Success",
              description: "Update Ahli Gizi is successfully",
              status: "success",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
            this.$router.push({
              name: "admin.nutritionists.detail",
              params: { id: this.id },
            });
          })
          .catch((err) => {
            this.$toast({
              title: "Failed",
              description: err.data.message ?? "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
      return this.createNutritionists(params)
        .then(() => {
          this.$toast({
            title: "Success",
            description: "Create Ahli Gizi is successfully",
            status: "success",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
          this.$router.push({
            name: "admin.nutritionists",
            query: { type: this.type },
          });
        })
        .catch((err) => {
          this.$toast({
            title: "Failed",
            description: err.data.message ?? "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
    onCancel() {
      if (this.id) {
        this.$router.push({
          name: "admin.nutritionists.detail",
          params: { id: this.id },
        });
      } else {
        this.$router.push({ name: "admin.nutritionists" });
      }
    },
    getPreviewImage(url) {
      if (url) {
        return url;
      }
      return require("@/assets/icon-photo.svg");
    },
    removeWorkExperience(index) {
      this.workExperiences.splice(index, 1);
    },
    addWorkExperience() {
      if (this.isEmptyworkExperiences) return;
      this.workExperiences.push({
        companyName: null,
        endYear: null,
        startYear: null,
      });
    },
    addEducation() {
      if (this.isEmptyEducation) return;
      this.education.push({
        level: null,
        title: null,
        university: null,
        otherUniversity: null,
      });
    },
    removeEducation(index) {
      this.education.splice(index, 1);
      if (this.education.length === 0) {
        this.education.push({
          level: null,
          title: null,
          university: null,
          otherUniversity: null,
        });
      }
    },
  },
  async mounted() {
    this.loadUniversities();
    if (!this.id) return;
    this.detailNutritionists(this.id);
  },
};
</script>

<style>
.ui.search.dropdown > input.search,
.ui.selection.dropdown > .dropdown.icon {
  top: auto;
}
.ui.dropdown .menu > .item {
  font-size: 16px;
}
.hidden {
  display: none;
}
</style>